var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-line-builder builder row" },
    [
      _vm.timeline
        ? _c(
            "div",
            { staticClass: "events-container column custom-scrollbar" },
            [
              _c(
                "transition-group",
                { attrs: { name: "flip-list", tag: "ul" } },
                _vm._l(_vm.events, function(event) {
                  return _c(
                    "li",
                    { key: event.id },
                    [_c("event-card", { attrs: { component: event } })],
                    1
                  )
                })
              ),
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  style: { order: _vm.events.lenght + 1 },
                  on: { click: _vm.addTimeLineEvent }
                },
                [_vm._v("\r\n        " + _vm._s(_vm.$t("addEventTag")))]
              )
            ],
            1
          )
        : _vm._e(),
      _c("properties-panel", { attrs: { "save-component": _vm.saveTimeLine } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }