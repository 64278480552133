var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.component
        ? _c(
            "div",
            {
              staticClass: "event-card-component column",
              class: { "component-active": _vm.isActive },
              on: { click: _vm.updateCurrentComponent }
            },
            [
              _c("div", { staticClass: "legend centered" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.date || _vm.$t("dateTag")) +
                    " - " +
                    _vm._s(_vm.title || _vm.$t("eventTitleTag")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "container-fluid dateTitle-col" },
                      [
                        _c("div", { staticClass: "row mt-0" }, [
                          _c("label", { attrs: { for: "text" } }, [
                            _vm._v(_vm._s(_vm.$t("dateTag")) + ":")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.date,
                                expression: "date"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("dateTag") + "..."
                            },
                            domProps: { value: _vm.date },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.date = $event.target.value
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("label", { attrs: { for: "text" } }, [
                            _vm._v(_vm._s(_vm.$t("eventTitleTag")) + ":")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.title,
                                expression: "title"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("titlePlaceHolder")
                            },
                            domProps: { value: _vm.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.title = $event.target.value
                              }
                            }
                          })
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "column timelineImageUpload" },
                    [
                      _c("image-upload", {
                        attrs: {
                          component: _vm.component,
                          options: _vm.imageUploadOptions
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "container-fluid" }, [
                _c(
                  "div",
                  { staticClass: "row mt-0" },
                  [
                    _c(
                      "label",
                      { staticClass: "mt-3", attrs: { for: "text" } },
                      [_vm._v(_vm._s(_vm.$t("descriptionTag")) + ":")]
                    ),
                    _c("ckeditor", {
                      attrs: {
                        editor: _vm.editor,
                        placeholder: _vm.$t("descriptionTag") + "...",
                        config: _vm.editorConfig
                      },
                      model: {
                        value: _vm.description,
                        callback: function($$v) {
                          _vm.description = $$v
                        },
                        expression: "description"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn secondary delete",
                  on: {
                    click: function($event) {
                      _vm.showModal = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("delete")))]
              )
            ]
          )
        : _vm._e(),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.removeTimeLineEvent,
                "text-button": _vm.$t("delete")
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "delete-message row" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("deleteConfirmationMessage", {
                        "0": _vm.$t("timeline")
                      })
                    )
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }