<template>
<div class="time-line-builder builder row" >
    <div class="events-container column custom-scrollbar" v-if="timeline">
        <transition-group name="flip-list"  tag="ul">
            <li  v-for="event in events" :key="event.id">
                <event-card :component="event"/>
            </li>
        </transition-group>
        <button @click="addTimeLineEvent" class="btn primary" :style="{order: events.lenght +1}">
        {{$t('addEventTag')}}</button>
    </div>
    <properties-panel :save-component="saveTimeLine"/>
</div>
</template>
<script>
import EventCard from '@/components/BackOffice/TimeLineBuilder/EventCard.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
export default {
    name: 'TimeLineBuilder',
    mixins:[BuilderMixin], 
    components:{EventCard},
    data(){
        return{
            timelineUpdatedMessage: this.$t('timelineUpdatedMessage'), 
            failedToUpdateTimelineMessage: this.$t('failedToUpdateTimelineMessage')
        }
    },
    computed:{
        events(){
            return this.timeline.blurbs
        },
        timeline(){
            return this.$store.state.timeLineBuilderComponent
        }
    },
    methods:{
        setComponentPropertyValue(component, value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: component, propertyName: propertyName, value: value})
        },
        saveTimeLine(){
            try {
                this.events.forEach(event => {
                    this.setComponentPropertyValue(event, event.audio.id, 'audioId')
                });
                this.$store.dispatch('updateTimeline', this.timeline)
                this.$store.commit('addSnackBarNotification',{ message: this.timelineUpdatedMessage})
            } catch(e){
                this.$store.commit('addSnackBarNotification',{message: this.failedToUpdateTimelineMessage})
            }
        }, 
        addTimeLineEvent(){
            this.$store.dispatch('addTimeLineEvent', this.timeline.id)
        }, 
        keydown(e){
            if(e.keyCode == 27)
                this.updateCurrentComponent()
        }, 
        //On ESC set timeline as current component to set Background
        updateCurrentComponent(){
            this.$store.commit('updateCurrentBackOfficeComponent',
                 this.timeline)
        }
    },
    created(){
         this.$store.commit('updateCurrentBackOfficeComponent',undefined)
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.dispatch('loadTimeLineBuilderComponent', this.$route.params.unitId)
        this.$store.commit('updateContentSectionState', true)
    }, 
    mounted(){
        document.addEventListener("keydown",this.keydown);
    },
    destroyed(){
        this.$store.commit('updateCurrentBackOfficeComponent',undefined)
        document.removeEventListener("keydown", this.keydown);
    }
}
</script>
<style lang="less" scoped>
.events-container{
    position: relative;
    padding-bottom: 24px;
    overflow-y: auto;
    width: 720px;
    margin-left: 32px;
    height: 100%;
    .btn{
        margin: auto;
        min-height: 32px;
        width: 122px;
        margin: 16px auto;
    }
}
</style>
